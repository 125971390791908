import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';

import '../../payment.css';

import Modal from 'react-modal';

import CloseIcon from '@mui/icons-material/Close';

const CheckoutForm = ({ amount, closePaymentModal, createOrder }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/response/stripe-pay.php`, {
          amount: amount,
          currency: 'usd',
        });
        setClientSecret(response.data.client_secret);
      } catch (error) {
        // setError('Error fetching client secret. Please try again.');
        setError('There was an error. Please try again.');
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      return;
    }

    const card = elements.getElement(CardElement);

    if (card == null) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setPaymentStatus(null);

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card,
        },
      });

      if (error) {
        setError(`${error.message}`);
        setPaymentStatus('Payment Failed');
      } else {
        setError(null);
        setPaymentStatus('Payment Successful');

        createOrder();
      }
    } catch (error) {
      setError(`${error.message}`);
      setPaymentStatus('Payment Failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center">
  <form onSubmit={handleSubmit} className="pay-form">
    <div className="form-group payment-body" id="payment-element">
      {/* <label htmlFor="card-element">Card details</label> */}
      <div className='input-container'>
        <CardElement
          id="card-element"
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
          className="custom-card-element"
        />
      </div>
    </div>
    {
      // paymentStatus == "Payment Successful" ? <></> : 
      <button type="submit" disabled={!stripe || isLoading} className="pay-button">
      {isLoading ? 'Please wait...' : 'Pay'}
    </button>
    }
    
    <div className='my-2 text-center'>{paymentStatus}</div>
    {error && <div className="error-message mt-2 text-center">{error}</div>}
  </form>
</div>

  );
};

// const customModalStyles = {
  

//   content: {
//     width: '80%', // Adjust the width as needed
//     maxWidth: '600px',
//     margin: 'auto',
//     display: 'flex',
//     // flexDirection: 'column',
//     justifyContent: 'center', // Center vertically
//     // alignItems: 'center', // Center horizontally
//     // borderRadius: '8%',
//   },
// };

const customModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    zIndex: 9999, // High enough to be above everything else
  },
  content: {
    width: '80%', 
    maxWidth: '700px', // Optional: Define a max width
    height: '40%',
    // maxHeight: '280px',
    // maxWidth: '480px',
    margin: 'auto',
    // display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    borderRadius: '20px',
    // zIndex: 100
  },
};



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const PaymentModal = ({ 
  isOpen, 
  closePaymentModal, 
  // registrationStatus, registrationError 
  createOrder, 
  amount
}) => {
  return (


    <Modal
    isOpen={isOpen}
    onRequestClose={closePaymentModal}
    // contentLabel=""
    style={customModalStyles}
  >


    
    
   
    <Elements stripe={stripePromise}>
      <CheckoutForm amount={amount} closePaymentModal={closePaymentModal} createOrder={createOrder}/>
    </Elements>

    </Modal>


    
  );
};


export default PaymentModal;
