import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from "./Header";
import Footer from "./Footer";

import HijabsForEveryday from "./HijabsForEveryday";
import ShopCollection from "./ShopCollection";
import OurBestSellers from "./OurBestSellers";

import logo from '../../assets/images/logo-08-final-1.png';
import wideimage from '../../assets/images/widescreen1.png';

import axios from 'axios';

import { motion } from 'framer-motion';
import { Carousel } from 'react-responsive-carousel';
import { useInView } from 'react-intersection-observer';

import slide1a from '../../assets/images/slide-1a.png';
import slide1b from '../../assets/images/slide-1b.png';
import slide2a from '../../assets/images/slide-2a.png';
import slide2b from '../../assets/images/slide-2b.png';
import slide3a from '../../assets/images/slide-3a.png';
import slide3b from '../../assets/images/slide-3b.png';

import NotificationModal from '../modals/NotificationModal';

import { AES } from 'crypto-js';

export default function HomePage({ options, cart, setSiteLanguage, addToCart, updateCart, removeCartItem, removeAllCartItem }) {
    const navigate = useNavigate();
    const [products, setProductsData] = useState([]);
    const [isDataloading, setIsDataLoading] = useState(true);
    const [isSubsLoading, setIsSubsLoading] = useState(true);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");
    // const [email, setEmail] = useState("");


    //notification modal
    const [notificationType, setNotificationType] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const openNotificationModal = (type, title, message) => {
      setNotificationType(type);
      setNotificationTitle(title);
      setNotificationMessage(message);
  
      setIsNotificationModalOpen(true);
    };
    const closeNotificationModal = () => {
      setIsNotificationModalOpen(false);
    };
    //notification modal

    useEffect(() => {
      // window.scrollTo(0, 0);
        handleData();
    }, []);

    const handleData = async () => {    

        setIsDataLoading(true);
        try {
    
          const response = await axios.get(process.env.REACT_APP_API_SERVER_URL + '/response/vh-read-items.php', {
            //params: { uid: uid },
            headers: {
              "Content-Type": "application/json",

            },
          });
    
          setIsDataLoading(false);
          //alert(JSON.stringify(response.data, null, 2));
    
          if (response.data.status) {
            //alert("dashboard-products " + JSON.stringify(response.data.itemsData, null, 2));
          
            // Store the retrieved data in state variables
    
            setProductsData(response.data.itemsData);
          } else {
            // alert("error1: " + response.data.message);
            openNotificationModal(false, "Valor Hijab", "error1: " + response.data.message);
          }
    
        } catch (error) {
          setIsDataLoading(false);
          // alert("error2: " + error);
          openNotificationModal(false, "Valor Hijab", "error2: " + error);
        }
      };

      const handleSubscribe = async () => {  
        
        if (isSubsLoading) {
          // alert('Please wait');
          openNotificationModal(false, "Valor Hijab", "Please wait..");
          return;
        }

        // Validate email before proceeding
        if (!isValidEmail(email)) {
          // alert('Please enter a valid email address');
          openNotificationModal(false, "Valor Hijab", "Please enter a valid email address");
          //setRegistrationStatus("Invalid email address");
          //setIsModalOpen(true);
          return;
        }



        setIsSubsLoading(true);
        try {
    
          const requestData = {
            email: email,
        };
        
        // alert(JSON.stringify(requestData));

        const response = await axios.post(process.env.REACT_APP_API_SERVER_URL + '/response/vh-create-subscribe.php', requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        
        setEmail(null);
        setIsSubsLoading(false);
          //alert(JSON.stringify(response.data, null, 2));
    
          if (response.data.status) {
            // alert("dashboard-products " + JSON.stringify(response.data.itemsData, null, 2));
            // alert("" + response.data.message);
            openNotificationModal(false, "Subscribe", "" + response.data.message);

            // Store the retrieved data in state variables
    
            // setProductsData(response.data.itemsData);
          } else {
            // alert("error1: " + response.data.message);
            openNotificationModal(false, "Subscribe", "error1: " + response.data.message);
          }
    
        } catch (error) {
          setIsSubsLoading(false);
          // alert("error2: " + error);
          openNotificationModal(false, "Subscribe", "error2: " + error);
        }
      };

      const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      const navigateToShop = () => {
        // alert('');
        navigate('/shop');
      }
      const itemsData = [
        {
            "Id": 18,
            "Created_On": "2024-12-25 15:14:11",
            "Created_By": "admin",
            "Last_Modified_By": "admin",
            "Last_Modified_On": "2024-12-25 15:22:37",
            "Item_Name": "Ribbed jersey",
            "Description": "Ribbed jersey",
            "Price": "14.99",
            "In_Stock": 99,
            "Color": "[\"Blue\",\"Black\",\"Green\",\"Teal\",\"Maroon\",\"Beige\",\"Pink\",\"Brown\",\"Gray\"]",
            "Size": null,
            "Reviews": null,
            "Image_Url": "IMG_5882.jpeg"
        },
        {
            "Id": 16,
            "Created_On": "2024-10-26 07:08:17",
            "Created_By": "Admin",
            "Last_Modified_By": "Admin",
            "Last_Modified_On": "2024-12-25 15:16:15",
            "Item_Name": "Undercap",
            "Description": "Undercap",
            "Price": "5.00",
            "In_Stock": 99,
            "Color": "[\"Black\",\"White\",\"Yellow\",\"Teal\",\"Pink\",\"Blue\",\"Green\",\"Violet\",\"Maroon\",\"Brown\"]",
            "Size": null,
            "Reviews": null,
            "Image_Url": "Undercap.jpeg"
        },
        {
            "Id": 4,
            "Created_On": "2024-10-26 07:08:17",
            "Created_By": "Admin",
            "Last_Modified_By": "Admin",
            "Last_Modified_On": "2024-12-25 15:11:51",
            "Item_Name": "Gift In A Box",
            "Description": "Gift In A Box",
            "Price": "5.00",
            "In_Stock": 50,
            "Color": "[\"White\"]",
            "Size": null,
            "Reviews": null,
            "Image_Url": "Gift In A Box.jpeg"
        }
    ];





      const [animateCarousel, setAnimateCarousel] = useState(false);
      const { ref, inView } = useInView({ threshold: 0.1, triggerOnce: true });
  
      const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
      const [menuWidthBuyit, setMenuWidthBuyit] = useState(0);
  
      const [activeIndex, setActiveIndex] = useState(0);
      const carouselItemVariants = {
        hidden: { opacity: 0, y: 200 },
        visible: { opacity: 1, y: 0, transition: { delay: 0.3, duration: 0.5, type: 'spring', stiffness: 20 } },
      };      
      const carouselItemVariants2 = {
        hidden: { opacity: 0, y: 200 },
        visible: { opacity: 1, y: 0, transition: { delay: 0.6, duration: 0.5, type: 'spring', stiffness: 20 } },
      };
      const handleIndicatorClick = (index) => {
        setActiveIndex(index);
      };
      useEffect(() => {    
        if (inView) {
          setAnimateCarousel(true);
        }

        if (hoveredMenuItem === 'BUYIT') {
          const buyitTextWidth = document.getElementById('buyit-text') && document.getElementById('buyit-text').offsetWidth;
          setMenuWidthBuyit(buyitTextWidth);
        } else {
          setMenuWidthBuyit(0);
        }

      }, [inView, hoveredMenuItem]);


      
      const navigateToProduct = (product) => {
              const encryptedData = AES.encrypt(JSON.stringify(product), 'encryptionKey').toString();
                navigate('/product-details', { state: { encryptedData } });
            };

    return (
    <div className='relative bg-valorHijabTheme h-full' style={{  }}>
        <Header  options={options} cart={cart} setSiteLanguage={setSiteLanguage} 
        addToCart={addToCart} updateCart={updateCart} removeCartItem={removeCartItem} 
        removeAllCartItem={removeAllCartItem} />


        





        <div className="wide-screen-div bg-cover bg-center -mt-16  relative z-20">
          <img src={wideimage} alt="Wide Screen Image" className="w-full" />
        </div>

{/* Main Contents */}
<div 
// className=' bg-white md:px-4 lg:px-16 xl:px-24 2xl:px-64  md:py-4  pb-4 
//     flex flex-col  '
    >



<img 
    src={'https://www.valorhijab.com/api/v1/vh-images/Pleated.jpeg'} //{process.env.REACT_APP_API_SERVER_URL + "/vh-images/" + parsedProduct.Image_Url}
    alt='overlay' 
    className='absolute inset-0 w-full h-full object-cover opacity-20' 
  />




<div className="flex flex-col md:flex-row  mb-2  ">
    
      <div className="w-full relative">
        <div className="">
          <motion.div
            variants={carouselItemVariants}
            initial="hidden"
            animate={ animateCarousel ? "visible" : "hidden"}
            ref={ref}
          >
            <Carousel
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={7500}
              swipeable={true}
              draggable={true}
              selectedItem={activeIndex} // Set selected item based on active index
              onChange={(index) => handleIndicatorClick(index)}

              renderIndicator={(onClickHandler, isSelected, index, label) => {
                const defStyle = { margin: 4, color: "white", cursor: "pointer" };
                const style = isSelected
                ? { ...defStyle, backgroundColor: "white" } // Use solid color when isSelected is true
                : { ...defStyle, backgroundColor: "transparent" };
                return (
                  <span
                  className="inline-block w-2 h-2 rounded-full border-2 border-white"
                  style={style}
                  onClick={() => {
                    onClickHandler();
                    handleIndicatorClick(index); // Update active index when an indicator is clicked
                  }}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    aria-label={`${label} ${index + 1}`}
                  >
                    {/* {"cust " + index} */}
                  </span>
                );
              }}
            >
              <div>
          <img src={slide1a} alt="Slide 1" />
        </div>
        <div>
          <img src={slide2a} alt="Slide 2" />
        </div>
        <div>
          <img src={slide3a} alt="Slide 3" />
        </div>
            </Carousel>
          </motion.div>
        </div>
      </div>

      <div className="w-full relative">
        <div className="">
          <motion.div
            variants={carouselItemVariants}
            initial="hidden"
            animate={ animateCarousel ? "visible" : "hidden"}
          >
            <Carousel
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={false}
              interval={7500}
              swipeable={false}
              draggable={false}
              selectedItem={activeIndex} // Set selected item based on active index

              renderIndicator={(onClickHandler, isSelected, index, label) => (
                <span
                  // className={`inline-block w-2 h-2 rounded-full border-2 border-white ${isSelected ? 'bg-white' : 'bg-transparent'}`}
                  // onClick={() => {
                  //   onClickHandler();
                  //   handleIndicatorClick(index); // Update active index when an indicator is clicked
                  // }}
                  // onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                />
              )}
            >
              <div className="relative inline-block">
                <img src={slide1b} alt="Slide 1"/>
                <div className="flex items-center justify-between absolute bottom-0 left-0 w-full text-black text-center p-2 px-8">
                  <span className='text-xs' style={{ paddingTop: '0px' }}></span>
                  {/* <span className='text-xs cursor-pointer' style={{ paddingTop: '0px' }}>Add to cart</span> */}
                  <div style={{ cursor: 'pointer' }} 
                                    onClick={
                                        () => {
                                          navigateToProduct(itemsData[0])
                                    }}
                                    className='md:ml-2 my-2 items-center bg-valorHijabTheme rounded-lg px-4 py-2 text-white text-sm text-center'>View
                                    </div>                  
                </div>
              </div>
        <div className="relative inline-block">
          <img src={slide2b} alt="Slide 2" />
          <div className="flex  items-center justify-between absolute bottom-0 left-0 w-full  text-black text-center p-2 px-8">
                  <span className='text-xs' style={{ paddingTop: '0px' }}></span>
                  {/* <span className='text-xs cursor-pointer' style={{ paddingTop: '0px' }}>Add to cart</span> */}
                  <div style={{ cursor: 'pointer' }} 
                                    onClick={
                                        () => {
                                          navigateToProduct(itemsData[1])
                                    }}
                                    className='md:ml-2 my-2 items-center bg-valorHijabTheme rounded-lg px-4 py-2 text-white text-sm text-center'>View
                                    </div>
                </div>
        </div>
        <div className="relative inline-block">
          <img src={slide3b} alt="Slide 3" />
          <div className="flex  items-center justify-between absolute bottom-0 left-0 w-full  text-black text-center p-2 px-8">
                  <span className='text-xs' style={{ paddingTop: '0px' }}></span>
                  {/* <span className='text-xs cursor-pointer' style={{ paddingTop: '0px' }}>Add to cart</span> */}
                  <div style={{ cursor: 'pointer' }} 
                                    onClick={
                                        () => {
                                          navigateToProduct(itemsData[2])
                                    }}
                                    className='md:ml-2 my-2 items-center bg-valorHijabTheme rounded-lg px-4 py-2 text-white text-sm text-center'>View
                                    </div>
                </div>
        </div>
            </Carousel>
          </motion.div>
        </div>
      </div>

    </div>




    <div className="flex flex-col flex-grow items-center px-10 pt-8 md:w-full lg:w-1/2 mx-auto ">
            <p className="mb-8 text-2xl text-white text-center">At Valor Hijab, we offer the hijabs you've always wanted, and ones you never imagined possible.</p>
            {/* <p className="mb-8 text-sm text-white text-center" style={{ lineHeight: '2.0' }}>Guided by the Islamic principle of ihsaan, which means striving for excellence, we focus on quality, style, and innovation to ensure our products stand out in the market. Each Valor Hijab is a timeless piece, crafted to provide you with comfort and confidence all day long.</p>         */}
          </div> 


<div className='w-full  rounded-lg'>
        <div className='container mx-auto'>
          <div className="flex flex-col flex-grow items-center  px-10 py-8  ">
            {/* <p className="mb-8 text-4xl text-white text-center">SPRING FORWARD IN STYLE</p> */}
            <div className="relative z-20  w-full md:w-1/4 border border-white valor-hijab-btn bg-valorHijabTheme rounded-lg  px-4 py-2 text-sm text-center md:ml-2" style={{ cursor: 'pointer' }} onClick={() => navigateToShop()}>SHOP NOW</div>
          </div>
          </div>
        </div>


        



        {/* <div className="mx-8 my-8 rounded-2xl  bg-white overflow-hidden">
            <div className="flex flex-col md:flex-row">
                <div className="flex flex-col flex-grow  px-10 py-8  bg-valorHijabTheme">
                    <p className="mb-8 text-4xl text-white">Welcome,</p>
                    <div className='  bg-slate-400 text-valorHijabTheme rounded-lg px-8 py-3 text-center' style={{ width: '225px' }}>Get Started</div>
                </div>
                <div className="flex flex-grow bg-white px-10 pt-8 ">
                    <img
                        className=""
                        src={logo}
                        alt="Logo"
                        onClick={() => {navigate('/');}}
                        style={{ cursor: 'pointer' }}
                    />
                    <p className="mb-8 text-4xl text-valorHijabTheme">Welcome,</p>
                </div>
             </div>
        </div> */}

<div className='w-full bg-valorHijabTheme'>
        <div className='container mx-auto'>
          {/* <ShopCollection products={products} addToCart={addToCart} isDataloading={isDataloading} navigateToShop={navigateToShop}/> */}
        </div>
</div>



{/* <div className="ps-home-collection">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                        <div className="ps-block--collection color orange">
                            <a
                                className="ps-block__overlay bg--bottom"
                                href="#"
                                style={{
                                    backgroundImage: `url(/static/img/collection/home-2/simple-1.jpg)`,
                                }}></a>
                            <p>Living Room</p>
                            <h5>Side Tables</h5>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                        <div className="ps-block--collection color green">
                            <a
                                className="ps-block__overlay bg--top"
                                href="#"
                                style={{
                                    backgroundImage: `url(/static/img/collection/home-2/simple-2.jpg)`,
                                }}
                                data-background="img/collection/home-2/simple-2.jpg"></a>
                            <p>Decor</p>
                            <h5>Vases & Bowls</h5>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 ">
                        <div className="ps-block--collection color pink">
                            <a
                                className="ps-block__overlay bg--bottom"
                                href="#"
                                style={{
                                    backgroundImage: `url(/static/img/collection/home-1/1.jpg)`,
                                }}></a>
                            <p>Kitchen</p>
                            <h5>Knife Sets</h5>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 ">
                        <div className="ps-block--collection color cyan">
                            <a
                                className="ps-block__overlay bg--top"
                                href="#"
                                style={{
                                    backgroundImage: `url(/static/img/collection/home-1/2.jpg)`,
                                }}></a>
                            <p>Bed & Bath</p>
                            <h5>Throw Rugs</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}


        
        {/* <HijabsForEveryday products={products} addToCart={addToCart} isDataloading={isDataloading} /> */}

{/* <div className='w-full '>
        <div className='container mx-auto'>
          <OurBestSellers products={products} addToCart={addToCart} isDataloading={isDataloading} navigateToShop={navigateToShop}/>
        </div>
</div> */}

        {/* <div className="mx-8 my-8  overflow-hidden">
            <p className='text-lg text-center mb-2'>Subscribe to be the first to know about new product releases, styling ideas and more</p>
            <div className='flex flex-col md:flex-row justify-center items-center'>
              <input 
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type='text'
                placeholder='Enter your e-mail address'
                className='w-full md:w-3/4 pl-4 border border-gray-300 rounded-lg py-1 px-2 text-sm text-center my-2 md:mr-2'
              />
              <div className="w-full md:w-1/4 valor-hijab-btn bg-valorHijabTheme rounded-lg  px-4 py-2 text-sm text-center md:ml-2" style={{ cursor: 'pointer' }} onClick={() => handleSubscribe()}>Subscribe</div>
            </div>
            <p style={{ fontSize: '10px' }} className='text-center'>Your email address is safe with us</p>
        </div> */}
        

{/* ` <div className=' bg-valorHijabTheme'>
        <div className="flex flex-col flex-grow items-center px-10 py-8 md:w-full lg:w-1/2 mx-auto">
        
            <p className='text-lg text-center mb-2'>Need help finding the perfect hijab?</p>

            <div className="flex flex-wrap">
                <div className="w-full md:w-1/2 px-2 mb-4">
                  <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)} 
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5"
                                placeholder='Name'
                  />
                </div>

                <div className="w-full md:w-1/2 px-2 mb-4">
                  <input
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5"
                                placeholder='Email'
                    />
                </div>

                <div className="w-full md:w-1/1 px-2 mb-4">
                  <input
                                type="text"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5"
                                placeholder='Phone number'
                    />
                </div>

                <div className="w-full md:w-1/1 px-2 mb-4">
                  <input
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5"
                                placeholder='Message'
                    />
                </div>


            </div>

        </div>
        <div className="flex flex-col flex-grow items-center  px-10 pb-8 ">
                <div className="w-full md:w-1/4 valor-hijab-btn bg-valorHijabTheme 
                rounded-lg  px-4 py-2 text-sm text-center md:ml-2" 
                style={{ cursor: 'pointer' }} onClick={() => {}}>Send</div>
            </div>

  </div>
   */}
</div>



        
        


        
        



        <Footer/>


        <NotificationModal
              isOpen={isNotificationModalOpen}
              onRequestClose={closeNotificationModal}
              notificationType={notificationType}
              notificationTitle={notificationTitle}
              notificationMessage={notificationMessage}
            />
    </div>
    );
  }
  